import React from "react"
import Layout from "../components/layout"

function LegalPage() {
  
  return (
    <Layout>
       
      <div className="mx-2 py-4 mx-2 sm:mx-auto max-w-xl font-sans text-primary">
        <h1 className="text-3xl leading-none text-primary font-black mb-8">
          Mentions légales
        </h1>
        <p>
          Le présent site Shift Your Job est la propriété de l'association THE SHIFTERS. 
          <br />
          Le contenu éditorial, textes, images animées ou non et base de données composant ce site Web, sont la propriété de l'association THE SHIFTERS.
          <br /> 
          Toute reproduction totale ou partielle de ce site est interdite sans autorisation expresse et préalable de l'association THE SHIFTERS. 
          <br />
          Cette représentation ou reproduction, par quelque procédé que ce soit, constitue une contrefaçon 
          sanctionnée par les articles L.3335-2 et suivants du Code de la propriété intellectuelle (legalis.net).
          <br />
          Tous les noms de produits ou de sociétés mentionnés dans le présent site sont les marques de leurs titulaires respectifs. 
          Enfin, les liens hypertextes mis en place dans le cadre du présent site web en direction d’autres ressources présentes 
          sur le réseau Internet ne sauraient engager la responsabilité de l'association THE SHIFTERS.
          <br />
        </p>
        <h3 className="font-bold mt-6">THE SHIFTERS</h3>
        <p>
          Adresse: 16-18, rue de Budapest - 75009 Paris
          <br />
          Téléphone: 06 72 94 47 65
          <br />
          Directeur de la publication: Pierre Bertrand
          <br />
        </p>
        <h2 className="font-bold text-xl md:text-3xl mt-12 mb-4">Technologies utilisées</h2>
        <h3 className="font-bold"><a href="https://www.gatsbyjs.com/">Gatsbyjs</a></h3>
        <h3 className="font-bold"><a href="https://airtable.com/">Airtable</a></h3>
        <h3 className="font-bold"><a href="https://tailwindcss.com/">Tailwindcss</a></h3>
        {/* <p>
        <h3 className="font-bold mt-6"><a href="https://gitlab.com/theshifters/carto-jobs-climat">Le code du site est ouvert et accessible sur GITLAB</a></h3>
        </p> */}

        <h2 className="font-bold text-xl md:text-3xl mt-12 mb-4">Hébergeur</h2>
        
        <h3 className="font-bold mt-6">GITLAB</h3>
        <p>
          Adresse : GitLab Ireland Limited, Unit 13 Classon House Dundrum
          Business Park - Dundrum, Dublin 14 - IRELAND
        </p>
      </div>
    </Layout>
  )
}

export default LegalPage
